import { Input, FieldWrapper } from "bumbag"
import { forwardRef, PropsWithoutRef } from "react"
import { useFormContext } from "react-hook-form"

export interface LabeledTextFieldProps extends PropsWithoutRef<JSX.IntrinsicElements["input"]> {
  /** Field name. */
  name: string
  /** Field label. */
  label: string
  /** Field type. Doesn't include radio buttons and checkboxes */
  type?: "text" | "password" | "email" | "number"
  outerProps?: PropsWithoutRef<JSX.IntrinsicElements["div"]>
}

export const LabeledTextField = forwardRef<HTMLInputElement, LabeledTextFieldProps>(
  ({ label, outerProps, name, ...props }, ref) => {
    const {
      register,
      formState: { isSubmitting, errors },
    } = useFormContext()
    const error = Array.isArray(errors[name])
      ? errors[name].join(", ")
      : errors[name]?.message || errors[name]

    const TextInput: any = Input

    return (
      <div {...outerProps}>
        <FieldWrapper label={label} state={error ? "danger" : undefined} validationText={error}>
          <TextInput disabled={isSubmitting} {...register(name)} {...props} />
        </FieldWrapper>
      </div>
    )
  }
)

export default LabeledTextField
